import {
  ASCENDING,
  type UndecoratedSequenceStep,
} from '@witmetrics/api-client';
import { API } from '@/api';
import { mapToKey, sortByKey } from '@/utils/arrays';

export const classes = {
  dialog:
    'px-0 py-0 h-[90vh] w-[90vw] max-w-[1200px] max-h-[1000px] flex flex-col',
  message: 'text-grey-7 text-xl w-full h-full flex items-center justify-center',
  viewer: 'grow flex justify-start',
};

export async function fetchSequenceSteps(sequenceID: number) {
  const steps = await fetchSteps(sequenceID);
  const files = steps.flatMap((s) => s.files.flatMap((sf) => sf.file));
  return { steps, files };
}

async function fetchSteps(sequenceID: number) {
  // TODO: fetch more if pagination.pageCount > 1
  const { results } = await API.Sequences.fetchSequenceSteps(sequenceID, {
    filters: [],
    pagination: { page: 1, pageSize: 50 },
    sorting: { sortBy: 'position', sortOrder: ASCENDING },
  });
  return results;
}

export function addFileToStep(stepID: number, fileID: number) {
  return API.Sequences.addFileToSequenceStep(stepID, fileID);
}

export function getOrderedIDs(steps: UndecoratedSequenceStep[]) {
  return mapToKey(sortByKey(steps, 'position', -1), 'id');
}

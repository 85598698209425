import { API } from '@/api';
import {
  COMPARISON_OPERATORS,
  type FetchMatchingUnisonProjectsResponse,
  FILTER_TYPES,
} from '@witmetrics/api-client';

export type SearchResults = FetchMatchingUnisonProjectsResponse['results'];

export const classes = {
  paper: 'fixed top-8 rounded-2xl',
  wrapper: 'flex flex-col w-[90vw] max-w-[800px] min-w-[200px] min-h-0',
  inputBase: 'w-full text-2xl px-6 py-4 flex-0',
  recentlyViewedWrapper: 'flex justify-between items-center px-6 py-0',
  recentlyViewed: 'text-base text-grey-8 font-medium',
  clearButton: 'text-grey-7 hover:text-grey-8',
  resultsList: 'flex-0 max-h-[40vh] overflow-auto',
  recentList: 'pt-0 mt-0',
};

export async function fetchMatchingProjects(query: string) {
  const timestamp = Date.now();
  // NOTE: Ignoring pagination
  const { results } = await API.UnisonProjects.fetchMatchingUnisonProjects({
    search: query,
    fields: [
      'name',
      'description',
      'user.firstname',
      'user.lastname',
      'user.externalRef',
    ],
    filters: [],
    pagination: { page: 1, pageSize: 20 },
  });
  return { timestamp, unisonProjects: results };
}

export async function fetchRecentProjects(projectIDs: number[]) {
  const { results } = await API.UnisonProjects.fetchUnisonProjects({
    pagination: { page: 1, pageSize: 5 },
    filters: [
      {
        filterType: FILTER_TYPES.COLUMN_VALUE,
        column: 'id',
        comparisonOperator: COMPARISON_OPERATORS.IN.value,
        value: projectIDs,
      },
    ],
  });
  if (!results) return [];
  // Match the original projectIDs order
  return results.sort(
    (a, b) => projectIDs.indexOf(a.id) - projectIDs.indexOf(b.id)
  );
}

'use client';
import { type PropsWithChildren, useEffect, useState } from 'react';
import { ErrorBoundary, Provider } from '@rollbar/react';
import { getEnv } from '@/utils/env';
import { NODE_ENV } from '@witmetrics/api-client';

export default function RollbarProvider({ children }: PropsWithChildren) {
  const [config, setConfig] = useState<{
    accessToken: string;
    environment: string;
  }>();

  useEffect(() => {
    setConfig({
      accessToken: '8abc3ad4bdb14da3803d797e8cf1c4fc',
      environment: getEnv(),
    });
  }, []);

  return !config || config.environment === NODE_ENV.DEVELOPMENT ? (
    <>{children}</>
  ) : (
    <Provider config={config}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </Provider>
  );
}

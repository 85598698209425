import { API } from '@/api';
import { COMPARISON_OPERATORS, FILTER_TYPES } from '@witmetrics/api-client';
import { endOfDay, parseISO, startOfDay } from 'date-fns';

export const classes = {
  overlay: 'h-full w-[750px] max-w-[98vw]',
  wrapper: 'px-8 w-full h-full flex flex-col flex-1',
  agenda: 'overflow-y-auto',
};

export async function fetchTasks(accountID: number, date: string) {
  // TODO: Pagination -- just doing a large pageSize for now
  const { results } = await API.Practices.fetchPracticeUnisonProjectTasks(
    accountID,
    {
      pagination: { page: 1, pageSize: 500 },
      filters: buildFilters(date),
    }
  );
  return results;
}

export function buildFilters(date: string) {
  return [
    {
      filterType: FILTER_TYPES.COLUMN_VALUE,
      comparisonOperator: COMPARISON_OPERATORS.GREATER_THAN_OR_EQUALS.value,
      value: startOfDay(parseISO(date)).toISOString(),
      column: 'dueAt',
    },
    {
      filterType: FILTER_TYPES.COLUMN_VALUE,
      comparisonOperator: COMPARISON_OPERATORS.LESS_THAN_OR_EQUALS.value,
      value: endOfDay(parseISO(date)).toISOString(),
      column: 'dueAt',
    },
  ];
}

import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useStep, useStepFilesBySequenceID } from '@/store/useStore';
import ViewerContent from './ViewerContent';
import FilesList from './FilesList';
import StepTitle, { type StepTitleProps } from './StepTitle';
import {
  filterOutValue,
  filterOutValues,
  mapToKey,
  mergeWithoutDuplicates,
} from '@/utils/arrays';
import { type FileType } from '@witmetrics/api-client';
import { classes, getGroupFileIDs } from './utils';

export type StepViewerProps = {
  className?: string;
  editable?: boolean;
  isLoading: boolean;
  sequenceID: number;
  stepID: number;
  onSaveFiles: StepTitleProps['onSaveFiles'];
  onOpenFiles: (fileIDs: number[]) => void;
};

export default function StepViewer({
  className,
  editable,
  isLoading,
  sequenceID,
  stepID,
  onSaveFiles,
  onOpenFiles,
}: StepViewerProps) {
  const step = useStep(stepID);
  const files = useStepFilesBySequenceID(sequenceID);
  const [openFileIDs, setOpenFileIDs] = useState<number[]>([]);
  const [hiddenFileIDs, setHiddenFileIDs] = useState<number[]>([]);
  const [hiddenFileTypes, setHiddenFileTypes] = useState<FileType[]>([]);

  const visibleFileIDs = openFileIDs.filter(
    (id) => !hiddenFileIDs.includes(id)
  );

  useEffect(() => {
    handleOpenStepFiles();
  }, [stepID, files]);

  const handleOpenStepFiles = () => {
    const fileIDs = mapToKey(
      files.filter((f) => f.sequenceStepID === stepID),
      'fileID'
    );
    onOpenFiles(fileIDs);
    setOpenFileIDs(fileIDs);
  };

  const handleToggleFileType = (fileType: FileType, isVisible: boolean) => {
    const fileIDs = getGroupFileIDs(files, fileType);
    if (isVisible) {
      setHiddenFileTypes(filterOutValue(hiddenFileTypes, fileType));
      // Un-hide any files of this type
      setHiddenFileIDs(filterOutValues(hiddenFileIDs, fileIDs));
    } else {
      setHiddenFileTypes(mergeWithoutDuplicates(hiddenFileTypes, [fileType]));
      // Hide all files of this type
      setHiddenFileIDs(mergeWithoutDuplicates(hiddenFileIDs, fileIDs));
    }
  };

  const handleToggleFile = (fileID: number) => {
    if (hiddenFileIDs.includes(fileID)) {
      onOpenFiles([fileID]);
      setHiddenFileIDs(filterOutValue(hiddenFileIDs, fileID));
    } else {
      setHiddenFileIDs(mergeWithoutDuplicates(hiddenFileIDs, [fileID]));
    }
  };

  const handleDeleteFile = (fileID: number) => {
    setOpenFileIDs(filterOutValue(openFileIDs, fileID));
  };

  if (!step) return null;

  return (
    <div className={twMerge(classes.wrapper, className)}>
      <div className={classes.sidebar}>
        <StepTitle
          editable={editable}
          isLoading={isLoading}
          stepID={stepID}
          onSaveFiles={onSaveFiles}
        />
        <FilesList
          editable={editable}
          stepID={stepID}
          visibleFileIDs={visibleFileIDs}
          hiddenFileTypes={hiddenFileTypes}
          onToggleFileType={handleToggleFileType}
          onToggleFile={handleToggleFile}
          onDeleteFile={handleDeleteFile}
        />
      </div>
      <ViewerContent isLoading={isLoading} openFileIDs={visibleFileIDs} />
    </div>
  );
}

import type { AppDispatch } from '@/store/store';
import {
  type DecoratedConversationUser,
  SOCKET_EVENTS,
} from '@witmetrics/api-client';
import type { Subscribe } from '@/types/sockets';
import {
  deleteConversationUser,
  setConversationUsers,
  updateConversationUser,
} from '@/store/slices/conversationUsersSlice';

const {
  ADD_CONVERSATION_USERS,
  UPDATE_CONVERSATION_USER,
  DELETE_CONVERSATION_USER,
} = SOCKET_EVENTS;

export function subscribeConversationUsers(
  dispatch: AppDispatch,
  subscribe: Subscribe
) {
  subscribe({
    endpoint: ADD_CONVERSATION_USERS,
    callback: ({
      conversationUsers,
    }: {
      conversationUsers: DecoratedConversationUser[];
    }) => dispatch(setConversationUsers(conversationUsers)),
  });
  subscribe({
    endpoint: UPDATE_CONVERSATION_USER,
    callback: ({
      conversationUser,
    }: {
      conversationUser: DecoratedConversationUser;
    }) => dispatch(updateConversationUser(conversationUser)),
  });
  subscribe({
    endpoint: DELETE_CONVERSATION_USER,
    callback: ({
      conversationID,
      userID,
    }: {
      conversationID: number;
      userID: number;
    }) => {
      dispatch(deleteConversationUser({ conversationID, userID }));
    },
  });
}

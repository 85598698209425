export const classes = {
  wrapper: 'rounded-full',
};

export const styles = {
  wrapper: (diameter: number) => ({
    height: `${diameter}px`,
    width: `${diameter}px`,
  }),
};

export const PROJECT_STATUS_CLASSES = {
  1: 'bg-cyan-2', // SCAN
  2: 'bg-blue-2', // DESIGN
  3: 'bg-navy-2', // APPROVED
  4: 'bg-yellow-2', // STARTED
  5: 'bg-green-2', // DONE
  6: 'bg-orange-2', // IN PROGRESS
  7: 'bg-pink-3', // PENDING APPROVAL
};

import FileInput from '@/components/FileInput';
import Tooltip from '@/components/Tooltip';
import { type FileType } from '@witmetrics/api-client';
import AddIcon from '@/icons/AddIcon';
import { classes } from './utils';

export type GroupFilesButtonProps = {
  isSaving: boolean;
  fileType: FileType;
  onSelectFiles: (files: File[], fileType: FileType) => void;
};

export default function GroupFilesButton({
  isSaving,
  fileType,
  onSelectFiles,
}: GroupFilesButtonProps) {
  return (
    <>
      <FileInput
        multiple
        disabled={isSaving}
        id={`new-project-files-${fileType}`}
        onSelect={(files) => onSelectFiles(files, fileType)}
      />
      <Tooltip title="Add File(s)">
        <label htmlFor={`new-project-files-${fileType}`}>
          <AddIcon className={classes.iconButton} />
        </label>
      </Tooltip>
    </>
  );
}

import {
  FILE_TYPE_IDS,
  FILE_TYPES,
  type FileType,
  type FileTypeID,
  type UndecoratedFile,
} from '@witmetrics/api-client';
import { formatNumber } from '@/utils/strings';
import { buildEmptyGroupedFiles } from '@/utils/files';

export const classes = {
  card: 'p-0 mx-2 my-4',
  message: 'px-4 text-lg text-grey-7 py-8 text-center',
  accordion: 'shadow-none w-full',
  checkbox: 'mr-2',
  list: 'mx-2',
};

export function getSectionLabel(
  fileType: FileType,
  groupedFiles: ReturnType<typeof groupFilesByType>
) {
  const label = FILE_TYPES[fileType].label;
  const count = groupedFiles[fileType].length;
  return count === 0 ? label : `${label} (${formatNumber(count)})`;
}

export function groupFilesByType(files: UndecoratedFile[]) {
  let groupedFiles = buildEmptyGroupedFiles<UndecoratedFile>();
  for (const file of files) {
    groupedFiles[getFileType(file)].push(file);
  }
  return groupedFiles;
}

function getFileType(file: UndecoratedFile) {
  return file.fileTypeID
    ? FILE_TYPE_IDS[file.fileTypeID as FileTypeID]
    : FILE_TYPES.EXTRA.name;
}

import { API } from '@/api';
import { buildColumnFilter } from '@/utils/filters';
import {
  COMPARISON_OPERATORS,
  type DecoratedUnisonProject,
  PROJECT_STATUSES,
} from '@witmetrics/api-client';
import { sortByUpdatedAt } from '@/utils/arrays';

const activeStatuses = Object.values(PROJECT_STATUSES)
  .filter((status) => status.name !== 'DONE')
  .map((status) => status.id);

export const STATUS_FILTER = buildColumnFilter(
  'unisonProjectStatusID',
  COMPARISON_OPERATORS.INCLUDES.value,
  activeStatuses
);

const PAGE_SIZE = 10;

export const classes = {
  list: 'max-h-[400px] overflow-auto',
};

export async function fetchActiveProjects() {
  return API.UnisonProjects.fetchUnisonProjects({
    pagination: { page: 1, pageSize: PAGE_SIZE },
    sorting: { sortBy: 'updatedAt', sortOrder: 'DESCENDING' },
    filters: [STATUS_FILTER],
  });
}

export function filterProjects(projects: DecoratedUnisonProject[]) {
  return sortByUpdatedAt(
    projects.filter((project) =>
      activeStatuses.includes(project.unisonProjectStatusID)
    )
  ).slice(0, PAGE_SIZE);
}

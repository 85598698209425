import type { AppDispatch } from '@/store/store';
import {
  type DecoratedFileCollectionFile,
  SOCKET_EVENTS,
} from '@witmetrics/api-client';
import type { Subscribe } from '@/types/sockets';
import {
  addFileCollectionFile,
  deleteFileCollectionFile,
} from '@/store/slices/fileCollectionFilesSlice';

const { CREATE_FILE_COLLECTION_FILE, UPDATE_FILE_COLLECTION_FILE } =
  SOCKET_EVENTS;

export function subscribeFileCollectionFiles(
  dispatch: AppDispatch,
  subscribe: Subscribe
) {
  subscribe({
    endpoint: CREATE_FILE_COLLECTION_FILE,
    callback: ({
      fileCollectionFile,
    }: {
      fileCollectionFile: DecoratedFileCollectionFile;
    }) => dispatch(addFileCollectionFile(fileCollectionFile)),
  });
  subscribe({
    endpoint: UPDATE_FILE_COLLECTION_FILE,
    callback: ({
      previousFileCollectionID,
      fileCollectionFile,
    }: {
      previousFileCollectionID: number;
      fileCollectionFile: DecoratedFileCollectionFile;
    }) => {
      dispatch(
        deleteFileCollectionFile({
          fileCollectionID: previousFileCollectionID,
          fileID: fileCollectionFile.fileID,
        })
      );
      dispatch(addFileCollectionFile(fileCollectionFile));
    },
  });
}

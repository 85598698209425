import { API } from '@/api';
import {
  COMPARISON_OPERATORS,
  type DecoratedUnisonProjectTask,
} from '@witmetrics/api-client';
import { sortByDueAt } from '@/utils/arrays';
import { buildColumnFilter } from '@/utils/filters';

const { INCLUDES, EQUALS } = COMPARISON_OPERATORS;

export const PAGE_SIZE = 20;

export const classes = {
  list: 'py-0 max-h-[50vh]',
};

export async function fetchTasks(practiceID: number, userID: number) {
  return API.Practices.fetchPracticeUnisonProjectTasks(practiceID, {
    pagination: { page: 1, pageSize: PAGE_SIZE },
    sorting: { sortBy: 'dueAt', sortOrder: 'ASCENDING' },
    filters: [
      buildColumnFilter('isResolved', INCLUDES.value, [false]),
      buildColumnFilter('assignedTo', EQUALS.value, userID),
    ],
  });
}

export function filterTasks(
  userID: number,
  tasks: DecoratedUnisonProjectTask[]
) {
  return sortByDueAt(
    tasks.filter(({ task }) => {
      return !task.isResolved && task.assignedTo.id === userID;
    })
  ).slice(0, PAGE_SIZE);
}

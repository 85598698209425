import { Container, type DropResult } from 'react-smooth-dnd';
import Title from '@/components/Title';
import Divider from '@/components/Divider';
import GroupFilesButton from '../GroupFilesButton';
import ListItem from './ListItem';
import { FILE_TYPES, type FileType } from '@witmetrics/api-client';
import { type GroupedFiles, type SelectedFile } from '../utils';
import { classes } from './utils';

export type SelectedFilesListProps = {
  isSaving: boolean;
  selectedFiles: GroupedFiles;
  onAddFiles: (files: File[], fileType: FileType) => void;
  onMoveFile: (dropResult: DropResult, fileType: FileType) => void;
  onUpdateFile: (selectedFile: SelectedFile, fileType: FileType) => void;
  onRemoveFile: (selectedFile: SelectedFile) => void;
};

export default function SelectedFilesList({
  isSaving,
  selectedFiles,
  onAddFiles,
  onMoveFile,
  onUpdateFile,
  onRemoveFile,
}: SelectedFilesListProps) {
  return (
    <>
      {Object.values(FILE_TYPES).map((fileType) => (
        <div key={fileType.name} className={classes.groupBlock}>
          <div className={classes.groupTitle}>
            <div className={classes.titleWrapper}>
              <Title size="sm" className={classes.title}>
                {fileType.label}
              </Title>
              <GroupFilesButton
                isSaving={isSaving}
                fileType={fileType.name}
                onSelectFiles={onAddFiles}
              />
            </div>
          </div>
          {/* @ts-ignore -- see https://github.com/kutlugsahin/react-smooth-dnd/issues/93 */}
          <Container
            groupName="file-groups"
            dragHandleSelector=".js-drag-handle"
            lockAxis="y"
            getChildPayload={(index) => selectedFiles[fileType.name][index]}
            onDrop={(dropData) => onMoveFile(dropData, fileType.name)}>
            {selectedFiles[fileType.name].map((sf) => (
              <ListItem
                key={sf.uuid}
                isSaving={isSaving}
                selectedFile={sf}
                onChange={onUpdateFile}
                onRemove={onRemoveFile}
              />
            ))}
          </Container>
          <Divider className={classes.divider} />
        </div>
      ))}
    </>
  );
}

import { useEffect, useRef, useState } from 'react';
import { type Notification } from '@witmetrics/api-client';
import IconButton from '@/components/IconButton';
import NotificationIcon from '@/icons/NotificationIcon';
import Badge from '@mui/material/Badge';
import NotificationsMenu from '@/components/NotificationsMenu';
import NotificationToaster from './NotificationToaster';
import { useToggle } from '@/hooks/useToggle';
import {
  useAllNotifications,
  useCurrentUser,
  useDispatch,
  useUnreadNotificationsCount,
} from '@/store/useStore';
import { setNotifications as dispatchSetNotifications } from '@/store/slices/notificationsSlice';
import { setUnreadNotificationsCount as dispatchSetUnreadNotificationsCount } from '@/store/slices/unreadNotificationsCountSlice';
import { useAppState } from '@/providers/AppStateProvider';
import { fetchNotifications, sortNotifications, styles } from './utils';

export type NotificationsProps = {
  className?: string;
};

export default function Notifications({ className }: NotificationsProps) {
  const unreadRef = useRef<number | null>(null);
  const menuToggleRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const { onApiError } = useAppState();
  const currentUser = useCurrentUser();
  const notifications = useAllNotifications();
  const unreadCount = useUnreadNotificationsCount();
  const [activeNotification, setActiveNotification] =
    useState<Notification | null>(null);
  const [isMenuOpen, toggleMenu] = useToggle(false);

  useEffect(() => {
    if (currentUser) fetchData();
  }, [currentUser]);

  useEffect(() => {
    if (unreadCount !== null) checkNewNotifications();
  }, [unreadCount]);

  const fetchData = async () => {
    try {
      const data = await fetchNotifications();
      dispatch(dispatchSetNotifications(data.notifications));
      dispatch(dispatchSetUnreadNotificationsCount(data.unreadCount));
    } catch (err) {
      onApiError(err, 'Error fetching notifications', () => fetchData());
    }
  };

  const checkNewNotifications = () => {
    /*
      If new unread count is greater than the current one,
      show a toaster for the most recent notification
     */
    if (unreadRef.current === null) unreadRef.current = unreadCount!;
    if (unreadCount! > unreadRef.current) {
      const notification = sortNotifications(notifications)[0];
      if (notification) setActiveNotification(notification);
    }
    if (unreadCount !== unreadRef.current) unreadRef.current = unreadCount;
  };

  return (
    <>
      <div className={className} ref={menuToggleRef}>
        <IconButton
          // @ts-ignore
          color={unreadCount && unreadCount > 0 ? 'purple-2' : 'grey-6'}
          onClick={() => toggleMenu()}>
          {/* @ts-ignore */}
          <Badge badgeContent={unreadCount} color="purple-2">
            <NotificationIcon
              sx={unreadCount && unreadCount > 0 ? styles.unread : {}}
            />
          </Badge>
        </IconButton>
      </div>
      {isMenuOpen && (
        <NotificationsMenu
          open
          anchorTo={menuToggleRef.current}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          notifications={sortNotifications(notifications)}
          onClose={() => toggleMenu()}
        />
      )}
      {activeNotification && (
        <NotificationToaster
          notification={activeNotification}
          onClose={() => setActiveNotification(null)}
        />
      )}
    </>
  );
}

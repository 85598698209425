import type { AppDispatch } from '@/store/store';
import {
  type DecoratedConversationMessage,
  SOCKET_EVENTS,
} from '@witmetrics/api-client';
import type { Subscribe } from '@/types/sockets';
import {
  addConversationMessage,
  deleteConversationMessage,
  updateConversationMessage,
} from '@/store/slices/conversationMessagesSlice';

const {
  CREATE_CONVERSATION_MESSAGE,
  UPDATE_CONVERSATION_MESSAGE,
  DELETE_CONVERSATION_MESSAGE,
} = SOCKET_EVENTS;

export function subscribeConversationMessages(
  dispatch: AppDispatch,
  subscribe: Subscribe
) {
  subscribe({
    endpoint: CREATE_CONVERSATION_MESSAGE,
    callback: ({
      conversationMessage,
    }: {
      conversationMessage: DecoratedConversationMessage;
    }) => dispatch(addConversationMessage(conversationMessage)),
  });
  subscribe({
    endpoint: UPDATE_CONVERSATION_MESSAGE,
    callback: ({
      conversationMessage,
    }: {
      conversationMessage: DecoratedConversationMessage;
    }) => dispatch(updateConversationMessage(conversationMessage)),
  });
  subscribe({
    endpoint: DELETE_CONVERSATION_MESSAGE,
    callback: ({
      conversationMessageID,
    }: {
      conversationMessageID: number;
    }) => {
      dispatch(deleteConversationMessage({ conversationMessageID }));
    },
  });
}

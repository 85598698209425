import type { AppDispatch } from '@/store/store';
import {
  addChecklist,
  deleteChecklist,
  updateChecklist,
} from '@/store/slices/checklistsSlice';
import { type DecoratedChecklist, SOCKET_EVENTS } from '@witmetrics/api-client';
import type { Subscribe } from '@/types/sockets';

const { CREATE_CHECKLIST, UPDATE_CHECKLIST, DELETE_CHECKLIST } = SOCKET_EVENTS;

export function subscribeChecklists(
  dispatch: AppDispatch,
  subscribe: Subscribe
) {
  subscribe({
    endpoint: CREATE_CHECKLIST,
    callback: ({ checklist }: { checklist: DecoratedChecklist }) =>
      dispatch(addChecklist(checklist)),
  });
  subscribe({
    endpoint: UPDATE_CHECKLIST,
    callback: ({ checklist }: { checklist: DecoratedChecklist }) =>
      dispatch(updateChecklist(checklist)),
  });
  subscribe({
    endpoint: DELETE_CHECKLIST,
    callback: ({ checklistID }: { checklistID: number }) => {
      dispatch(deleteChecklist({ checklistID }));
    },
  });
}

'use client';
import {
  createContext,
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { type Notification, UnauthorizedError } from '@witmetrics/api-client';
import { showErrorToaster } from '@/utils/toasters';
import { useActiveAccount, useDispatch } from '@/store/useStore';
import { setActiveAccount as dispatchSetActiveAccount } from '@/store/slices/activeAccountSlice';
import { DIALOGS } from '@/constants/dialogs';
import { useSidebarCollapsed } from '@/hooks/useSidebarCollapsed';
import { usePath } from '@/hooks/usePath';
import type { Dictionary } from '@/types';
import { useSockets } from '@/providers/SocketsProvider';
import { OVERLAYS } from '@/constants/overlays';
import { filterOutValue } from '@/utils/arrays';
import { getCRMURL } from '@/api/getURL';
import {
  DEFAULT_DIALOGS,
  DEFAULT_OVERLAYS,
  isLeadNotification,
  isProjectNotification,
  isSequenceNotification,
  isSequenceStepNotification,
  updateActiveAccount,
  updateReadNotification,
  useStoredAccountID,
} from './utils';

type AppStateValue = {
  isSidebarCollapsed: boolean | undefined;
  activeProjectID: number | null;
  recentProjectIDs: number[];
  activeCalendarDate: string | null;
  activeDialogs: Dictionary<boolean>;
  activeOverlays: Dictionary<boolean>;
  onToggleSidebarCollapsed: (isCollapsed?: boolean) => void;
  setActiveDialogs: Dispatch<SetStateAction<{ [p: string]: boolean }>>;
  onLogin: () => void;
  onApiError: (err: any, message: string, cb?: Function) => void;
  onClearActiveProject: () => void;
  onClearRecentProjects: () => void;
  onSelectProject: (projectID: number, params?: Dictionary<any>) => void;
  onStartTask: (date: string) => void;
  onOpenAgenda: (date: string) => void;
  onOpenNotification: (notification: Notification) => void;
  onToggleUnloadWarning: (activate: boolean) => void;
  onOpenDialog: (dialogName: string) => void;
  onCloseDialog: (dialogName: string) => void;
  onOpenOverlay: (overlayName: string) => void;
  onCloseOverlay: (overlayName: string) => void;
};

const AppStateContext = createContext<AppStateValue>({
  isSidebarCollapsed: false,
  activeProjectID: null,
  recentProjectIDs: [],
  activeCalendarDate: null,
  activeDialogs: DEFAULT_DIALOGS,
  activeOverlays: DEFAULT_OVERLAYS,
  onToggleSidebarCollapsed: () => null,
  setActiveDialogs: () => null,
  onLogin: () => null,
  onApiError: () => null,
  onClearActiveProject: () => null,
  onClearRecentProjects: () => null,
  onSelectProject: () => null,
  onStartTask: () => null,
  onOpenAgenda: () => null,
  onOpenNotification: () => null,
  onToggleUnloadWarning: () => null,
  onOpenDialog: () => null,
  onCloseDialog: () => null,
  onOpenOverlay: () => null,
  onCloseOverlay: () => null,
});

export default function AppStateProvider({ children }: PropsWithChildren) {
  const activeProjectRef = useRef<number | null>(null);
  const loginCallbacks = useRef<Function[]>([]);
  const dispatch = useDispatch();
  const activeAccount = useActiveAccount();
  const { setApiErrorCallback } = useSockets();
  const { navigate, pushParams, removeParams } = usePath();
  const [activeProjectID, setActiveProjectID] = useState<number | null>(null);
  const [recentProjectIDs, setRecentProjectIDs] = useState<number[]>([]);
  const [activeCalendarDate, setActiveCalendarDate] = useState<string | null>(
    null
  );
  const [activeDialogs, setActiveDialogs] = useState(DEFAULT_DIALOGS);
  const [activeOverlays, setActiveOverlays] = useState(DEFAULT_OVERLAYS);
  const [isSidebarCollapsed, toggleSidebarCollapsed] = useSidebarCollapsed();
  const [storedAccountID, setStoredAccountID] = useStoredAccountID();

  useEffect(() => {
    setApiErrorCallback(handleSocketError);
  }, []);

  useEffect(() => {
    if (!activeAccount || !storedAccountID) return;
    if (activeAccount.accountID !== storedAccountID) {
      handleResetAccountItems();
    }
  }, [activeAccount]);

  const handleResetAccountItems = () => {
    setActiveProjectID(activeProjectRef.current);
    activeProjectRef.current = null;
    setRecentProjectIDs([]);
    setStoredAccountID(activeAccount!.accountID);
  };

  const handleOpenDialog = (dialogName: string) => {
    setActiveDialogs((dialogs) => ({ ...dialogs, [dialogName]: true }));
  };

  const handleCloseDialog = (dialogName: string) => {
    setActiveDialogs((dialogs) => ({ ...dialogs, [dialogName]: false }));
  };

  const handleOpenOverlay = (overlayName: string) => {
    setActiveOverlays((overlays) => ({ ...overlays, [overlayName]: true }));
  };

  const handleCloseOverlay = (overlayName: string) => {
    setActiveOverlays((overlays) => ({ ...overlays, [overlayName]: false }));
    if (overlayName === OVERLAYS.AGENDA) {
      setActiveCalendarDate(null);
    }
  };

  const handleSocketError = () => {
    handleApiError(new UnauthorizedError('', ''), '');
  };

  const handleApiError = (err: any, toasterMessage: string, cb?: Function) => {
    if (err instanceof UnauthorizedError) {
      if (cb) loginCallbacks.current.push(cb);
      if (!activeDialogs[DIALOGS.LOGIN]) handleOpenDialog('login');
    } else {
      showErrorToaster(toasterMessage);
      throw err;
    }
  };

  const handleSetActiveProject = (
    projectID: number,
    params?: Dictionary<any>
  ) => {
    if (projectID === activeProjectID) return null;
    // Optionally include extra params for selecting a tab, conversation, etc.
    pushParams(
      params
        ? { ...params, activeProjectID: projectID }
        : { activeProjectID: projectID }
    );
    setActiveProjectID(projectID);
    if (recentProjectIDs.includes(projectID)) {
      setRecentProjectIDs((ids) =>
        [projectID, ...filterOutValue(ids, projectID)].slice(0, 5)
      );
    } else {
      setRecentProjectIDs((ids) => [projectID, ...ids].slice(0, 5));
    }
  };

  const handleClearActiveProject = () => {
    setActiveProjectID(null);
    removeParams([
      'activeProjectID',
      'projectOverlayTab',
      'activeCollectionID',
    ]);
  };

  const handleStartTask = (date: string) => {
    setActiveCalendarDate(date);
    handleOpenDialog(DIALOGS.TASK);
  };

  const handleOpenAgenda = (date: string) => {
    setActiveCalendarDate(date);
    handleOpenOverlay(OVERLAYS.AGENDA);
  };

  const handleLogin = () => {
    if (loginCallbacks.current) {
      loginCallbacks.current.forEach((cb) => cb());
    }
    handleCloseDialog(DIALOGS.LOGIN);
    loginCallbacks.current = [];
  };

  const handleToggleSidebar = (isCollapsed?: boolean) => {
    if (isCollapsed === undefined) {
      toggleSidebarCollapsed(!isSidebarCollapsed);
    } else {
      toggleSidebarCollapsed(isCollapsed);
    }
  };

  const handleToggleUnloadWarning = (activate: boolean) => {
    if (activate) handleActivateUnloadWarning();
    else handleDeactivateUnloadWarning();
  };

  const handleActivateUnloadWarning = () => {
    window.removeEventListener('beforeunload', handleUnloadWarning); // Prevent duplicate listeners
    window.addEventListener('beforeunload', handleUnloadWarning);
  };

  const handleDeactivateUnloadWarning = () => {
    window.removeEventListener('beforeunload', handleUnloadWarning);
  };

  const handleUnloadWarning = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = '';
  };

  const handleOpenNotification = async (notification: Notification) => {
    // Make sure the correct account is active
    if (notification.practiceID !== activeAccount?.accountID) {
      const account = await updateActiveAccount(notification.practiceID);
      if (isProjectNotification(notification)) {
        // Prevent activeProjectID from being cleared by handleResetAccountItems
        activeProjectRef.current = notification.content.unisonProjectID;
      }
      dispatch(dispatchSetActiveAccount(account));
    }

    // No need to wait on this, the socket event will handle it
    if (!notification.isRead) updateReadNotification(notification.id);

    // Determine what to open / navigate to
    if (isProjectNotification(notification)) {
      return handleSetActiveProject(notification.content.unisonProjectID);
    } else if (isSequenceNotification(notification)) {
      // TODO
      return showErrorToaster('Sequence notifications not yet implemented');
    } else if (isSequenceStepNotification(notification)) {
      // TODO
      return showErrorToaster('Sequence notifications not yet implemented');
    } else if (isLeadNotification(notification)) {
      navigate(getCRMURL(), { activeLeadID: notification.content.leadID });
    } else {
      showErrorToaster('Error opening notification');
      throw Error(
        // @ts-ignore
        `Unrecognized notificationTypeID: ${notification.notificationTypeID}`
      );
    }
  };

  const contextValue = {
    isSidebarCollapsed,
    activeProjectID,
    recentProjectIDs,
    activeCalendarDate,
    activeDialogs,
    activeOverlays,
    onToggleSidebarCollapsed: handleToggleSidebar,
    setActiveDialogs,
    onLogin: handleLogin,
    onApiError: handleApiError,
    onClearActiveProject: handleClearActiveProject,
    onClearRecentProjects: () => setRecentProjectIDs([]),
    onSelectProject: handleSetActiveProject,
    onStartTask: handleStartTask,
    onOpenAgenda: handleOpenAgenda,
    onOpenNotification: handleOpenNotification,
    onToggleUnloadWarning: handleToggleUnloadWarning,
    onOpenDialog: handleOpenDialog,
    onCloseDialog: handleCloseDialog,
    onOpenOverlay: handleOpenOverlay,
    onCloseOverlay: handleCloseOverlay,
  };

  return (
    <AppStateContext.Provider value={contextValue}>
      {children}
    </AppStateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(AppStateContext);
  if (!context) {
    throw Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}

'use client';
import { NODE_ENV } from '@witmetrics/api-client';

export function getEnv() {
  if (urlIncludes('localhost') || urlIncludes('ngrok.io')) {
    return NODE_ENV.DEVELOPMENT;
  } else if (urlIncludes('testing.')) {
    return NODE_ENV.TESTING;
  } else return NODE_ENV.PRODUCTION;
}

function urlIncludes(str: string) {
  return typeof window === 'object' && window.location.href.includes(str);
}

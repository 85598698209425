export const classes = {
  wrapper: 'flex items-center justify-start pl-1 pt-1 pb-1',
  dueAt: 'font-medium text-sm',
  resolved: 'text-grey-5 line-through',
  today: 'text-orange-3 font-bold',
  tomorrow: 'text-purple-3 font-bold',
  past: 'text-pink-3 font-bold',
  future: 'text-grey-7',
  icon: 'text-lg text-grey-6 mr-2',
};

import type { AppDispatch } from '@/store/store';
import {
  type DecoratedUnisonProjectConversation,
  SOCKET_EVENTS,
} from '@witmetrics/api-client';
import type { Subscribe } from '@/types/sockets';
import { addUnisonProjectConversation } from '@/store/slices/unisonProjectConversationsSlice';

const { CREATE_UNISON_PROJECT_CONVERSATION } = SOCKET_EVENTS;

export function subscribeUnisonProjectConversations(
  dispatch: AppDispatch,
  subscribe: Subscribe
) {
  subscribe({
    endpoint: CREATE_UNISON_PROJECT_CONVERSATION,
    callback: ({
      unisonProjectConversation,
    }: {
      unisonProjectConversation: DecoratedUnisonProjectConversation;
    }) => dispatch(addUnisonProjectConversation(unisonProjectConversation)),
  });
}

import type { AppDispatch } from '@/store/store';
import { updateChecklist } from '@/store/slices/checklistsSlice';
import {
  type DecoratedChecklist,
  type DecoratedChecklistItem,
  SOCKET_EVENTS,
} from '@witmetrics/api-client';
import type { Subscribe } from '@/types/sockets';
import {
  addChecklistItem,
  deleteChecklistItem,
  updateChecklistItem,
} from '@/store/slices/checklistItemsSlice';

const { CREATE_CHECKLIST_ITEM, UPDATE_CHECKLIST_ITEM, DELETE_CHECKLIST_ITEM } =
  SOCKET_EVENTS;

export function subscribeChecklistItems(
  dispatch: AppDispatch,
  subscribe: Subscribe
) {
  subscribe({
    endpoint: CREATE_CHECKLIST_ITEM,
    callback: ({
      checklist,
      item,
    }: {
      checklist: DecoratedChecklist;
      item: DecoratedChecklistItem;
    }) => {
      dispatch(updateChecklist(checklist));
      dispatch(addChecklistItem(item));
    },
  });
  subscribe({
    endpoint: UPDATE_CHECKLIST_ITEM,
    callback: ({ checklistItem }: { checklistItem: DecoratedChecklistItem }) =>
      dispatch(updateChecklistItem(checklistItem)),
  });
  subscribe({
    endpoint: DELETE_CHECKLIST_ITEM,
    callback: ({
      itemID,
      checklist,
    }: {
      itemID: number;
      checklist: DecoratedChecklist;
    }) => {
      dispatch(deleteChecklistItem({ itemID }));
      dispatch(updateChecklist(checklist));
    },
  });
}

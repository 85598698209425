import LoopingEllipsis from '@/components/LoopingEllipsis';
import Card from '@mui/material/Card';
import { classes, styles } from './utils';

export default function LoadingApp() {
  return (
    <div className={classes.wrapper}>
      <Card raised sx={styles.card} className={classes.card}>
        <div className={classes.text}>
          <span>Loading</span>
          <LoopingEllipsis />
        </div>
      </Card>
    </div>
  );
}

import type { AppDispatch } from '@/store/store';
import {
  type DecoratedUnisonProject,
  SOCKET_EVENTS,
} from '@witmetrics/api-client';
import type { Subscribe } from '@/types/sockets';
import {
  addUnisonProject,
  deleteUnisonProject,
  updateUnisonProject,
} from '@/store/slices/unisonProjectsSlice';

const { CREATE_UNISON_PROJECT, UPDATE_UNISON_PROJECT, DELETE_UNISON_PROJECT } =
  SOCKET_EVENTS;

export function subscribeUnisonProjects(
  dispatch: AppDispatch,
  subscribe: Subscribe
) {
  subscribe({
    endpoint: CREATE_UNISON_PROJECT,
    callback: ({ unisonProject }: { unisonProject: DecoratedUnisonProject }) =>
      dispatch(addUnisonProject(unisonProject)),
  });
  subscribe({
    endpoint: UPDATE_UNISON_PROJECT,
    callback: ({ unisonProject }: { unisonProject: DecoratedUnisonProject }) =>
      dispatch(updateUnisonProject(unisonProject)),
  });
  subscribe({
    endpoint: DELETE_UNISON_PROJECT,
    callback: ({ projectID }: { projectID: number }) => {
      dispatch(deleteUnisonProject({ projectID }));
    },
  });
}

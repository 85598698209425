import type { AppDispatch } from '@/store/store';
import { type DecoratedTask, SOCKET_EVENTS } from '@witmetrics/api-client';
import type { Subscribe } from '@/types/sockets';
import { addTask, deleteTask, updateTask } from '@/store/slices/tasksSlice';

const { CREATE_TASK, UPDATE_TASK, DELETE_TASK } = SOCKET_EVENTS;

export function subscribeTasks(dispatch: AppDispatch, subscribe: Subscribe) {
  subscribe({
    endpoint: CREATE_TASK,
    callback: ({ task }: { task: DecoratedTask }) => dispatch(addTask(task)),
  });
  subscribe({
    endpoint: UPDATE_TASK,
    callback: ({ task }: { task: DecoratedTask }) => dispatch(updateTask(task)),
  });
  subscribe({
    endpoint: DELETE_TASK,
    callback: ({ taskID }: { taskID: number }) => {
      dispatch(deleteTask({ taskID }));
    },
  });
}

import Menu, { MenuItem, type MenuProps } from '@/components/Menu';
import ProjectStatusCircle from '@/components/ProjectStatusCircle';
import { PROJECT_STATUSES } from '@witmetrics/api-client';
import { classes } from './utils';

export interface ProjectStatusDropdownProps extends MenuProps {
  onChange: (projectStatusID: number) => void;
}

export default function ProjectStatusDropdown({
  open,
  anchorTo,
  anchorOrigin,
  transformOrigin,
  onChange,
  onClose,
}: ProjectStatusDropdownProps) {
  const handleChange = (projectStatusID: number) => {
    onChange(projectStatusID);
    onClose();
  };

  return (
    <Menu
      open={open}
      className={classes.menu}
      anchorTo={anchorTo}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onClose={onClose}>
      <ul>
        {Object.values(PROJECT_STATUSES).map((status) => (
          <MenuItem
            key={status.id}
            label={status.label}
            icon={
              <ProjectStatusCircle
                className={classes.icon}
                projectStatusID={status.id}
                size={18}
              />
            }
            onClick={() => handleChange(status.id)}
          />
        ))}
      </ul>
    </Menu>
  );
}

import { API } from '@/api';
import { buildColumnFilter } from '@/utils/filters';
import { COMPARISON_OPERATORS } from '@witmetrics/api-client';

export const classes = {
  card: 'px-0 py-0 mt-4',
  ordersList: 'w-full max-h-[800px] py-0',
  wrapper:
    'flex items-start justify-start overflow-x-auto h-full w-full px-2 py-8',
};

export async function fetchChecklistTemplates(practiceID: number) {
  // NOTE: Ignoring pagination for the time being -- unlikely to have more than 100 checklist templates
  const { results } = await API.Practices.fetchPracticeChecklists(practiceID, {
    pagination: { page: 1, pageSize: 100 },
    filters: [
      buildColumnFilter('isTemplate', COMPARISON_OPERATORS.IS.value, true),
    ],
  });
  return results;
}

import { UndecoratedUnisonProject } from '@witmetrics/api-client';
import { API } from '@/api';
import { buildUserOption } from '@/utils/filters';
import { getRoundedDateTime } from '@/utils/time';

export const classes = {
  dialog: 'w-[98vw] max-w-[450px] min-w-[350px]',
  body: 'w-full max-h-[80vh] min-h-[100px] pt-2 pb-12 overflow-auto',
  input: 'w-full my-4',
};

type UserOption = { value: number; label: string };

export function getInitialDate(
  date: string | null,
  task?: { dueAt: string } | null
) {
  // Default to now if a value isn't already provided by the task or via app state
  let initialDate = new Date();
  if (task?.dueAt) initialDate = new Date(task.dueAt);
  else if (date) initialDate = new Date(date);
  return new Date(getRoundedDateTime(initialDate.toISOString()));
}

export function validateFields({
  project,
  assignedTo,
  description,
}: {
  project: any;
  assignedTo: any;
  description: string;
}) {
  let invalidFields = [];
  if (description.trim().length === 0) invalidFields.push('description');
  if (!project) invalidFields.push('project');
  if (!assignedTo) invalidFields.push('assignedTo');
  return invalidFields;
}

export function buildTaskData({
  dueAt,
  userID,
  assignedTo,
  description,
}: {
  dueAt: Date;
  userID?: number;
  assignedTo: UserOption;
  description: string;
}) {
  return {
    dueAt: dueAt.toISOString(),
    userID,
    assignedTo: assignedTo.value,
    description: description.trim(),
  };
}

export function buildProjectOption(project: UndecoratedUnisonProject) {
  return { value: project.id, label: project.name };
}

export async function fetchPracticeUsers(practiceID: number) {
  const users = await API.Practices.fetchPracticeUsers(practiceID, {
    filters: [],
    pagination: { page: 1, pageSize: 100 },
  });
  return users.results.map(({ user }) => buildUserOption(user));
}

export async function searchAssignedTo(
  searchQuery: string,
  practiceUsers: { value: number; label: string }[]
) {
  const query = searchQuery.trim().toLowerCase();
  let suggestions = [...practiceUsers];
  if (query.length > 0) {
    suggestions = suggestions.filter(({ label }) =>
      label.toLowerCase().includes(query)
    );
  }
  return suggestions;
}

export async function fetchMatchingProjects(query: string) {
  const timestamp = Date.now();
  // NOTE: Ignoring pagination
  const { results } = await API.UnisonProjects.fetchMatchingUnisonProjects({
    search: query,
    fields: ['name', 'description', 'user.firstname', 'user.lastname'],
    filters: [],
    pagination: { page: 1, pageSize: 20 },
  });
  return results.map(buildProjectOption);
}

export async function createNewTask(
  projectID: number,
  data: ReturnType<typeof buildTaskData>
) {
  const task = await API.Tasks.createNewTask(data);
  const unisonProjectTask = await API.UnisonProjects.addTaskToUnisonProject(
    projectID,
    task.id
  );
  return { task, unisonProjectTask };
}

export function updateTask(
  taskID: number,
  data: ReturnType<typeof buildTaskData>
) {
  return API.Tasks.updateTask(taskID, data);
}
